import { virtual } from 'haunted';
import { html } from 'lit-html';

export const Loading = virtual(() => {
  return html`
    <div class="loading">
      <div class="loading__inner-1">
        <div class="loading__inner-2"></div>
      </div>
    </div>
  `;
});